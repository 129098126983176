import React from 'react'
import { Fragment } from 'react'
//import AdSense from 'react-adsense';

// const Magnet = props => (
//   <Fragment>
//     <br />
//     <Divider />
//     {/*  <AdSense.Google
//             client='ca-pub-5773888715765748'
//             slot='2560392537'
//             style={{ display: 'block' }}
//             format='auto'
//             responsive='true'
//             layoutKey='-gw-1+2a-9x+5c'
//           />*/}
//     This is the magnet
//     <Divider />
//     <br />
//   </Fragment>
// )

import { useStaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box, Button, Heading, Text } from 'theme-ui'

const styles = {
  wrapper: src => ({
    alignItems: `center`,
    flexDirection: [`column`, `row`],
    bg: `omegaDarker`,
    backgroundImage: src && [`none`, `none`, `url(${src})`],
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `70% bottom`,
    borderRadius: `default`,
    width: `full`,
    p: 4
  }),
  left: {
    flexBasis: `2/3`
  },
  right: {
    flexBasis: `1/3`,
    textAlign: `right`
  },
  heading: {
    color: `betaLight`,
    fontWeight: `normal`
  },
  subheading: {
    color: `omega`,
    mb: [3, 0]
  }
}

const Magnet = () => {
  const data = useStaticQuery(bannerHorizontalQuery2)
  const { file } = data

  const banner = file && file.banner && file.banner.regular

  return (
    <Fragment>
      <Flex sx={styles.wrapper(banner && banner.src)}>
        <Box sx={styles.left}>
          <Heading variant='h3' sx={styles.heading}>
            Given When Then Cheat Sheet
          </Heading>
          <Text sx={styles.subheading}>
            All you need to know about given when then syntax in one place.
          </Text>
        </Box>
        <Box sx={styles.right}>
          <Button
            as={Link}
            to='https://www.buymeacoffee.com/agileambition/e/233228?utm_source=agileambition&utm_medium=pdf&utm_campaign=hook&utm_content=given-when-then'
            variant='primary'
            aria-label='Download Now'
          >
            Download Now
          </Button>
        </Box>
      </Flex>

      <br />
    </Fragment>
  )
}

const bannerHorizontalQuery2 = graphql`
  query BannerHorizontalQuery2 {
    file(
      absolutePath: { regex: "/given-when-then-cheatsheet.(jpeg|jpg|gif|png)/" }
    ) {
      banner: childImageSharp {
        regular: resize(height: 150, cropFocus: CENTER) {
          src
        }
      }
    }
  }
`

export default Magnet
