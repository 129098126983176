import React from 'react'
import { Fragment } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box, Button, Heading, Text } from 'theme-ui'

const styles = {
  wrapper: src => ({
    alignItems: `center`,
    flexDirection: [`column`, `row`],
    bg: `omegaDarker`,
    backgroundImage: src && [`none`, `none`, `url(${src})`],
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `70% bottom`,
    borderRadius: `default`,
    width: `full`,
    p: 4
  }),
  left: {
    flexBasis: `2/3`
  },
  right: {
    flexBasis: `1/3`,
    textAlign: `right`
  },
  heading: {
    color: `betaLight`,
    fontWeight: `normal`
  },
  subheading: {
    color: `omega`,
    mb: [3, 0]
  }
}

const NewsletterAd = () => {
  const data = useStaticQuery(bannerHorizontalQuery4)
  const { file } = data

  const banner = file && file.banner && file.banner.regular

  return (
    <Fragment>
      <Flex sx={styles.wrapper(banner && banner.src)}>
        <Box sx={styles.left}>
          <Heading variant='h3' sx={styles.heading}>
            Discover Better Ways of Working
          </Heading>
          <Text sx={styles.subheading}>
            Subscribe and discover better ways of working. Piece together agile
            understanding with clarifying metaphors, curated resources,
            foundational terminology, and illuminating quotes, delivered
            monthly. Join now to start unpuzzling agile piece by piece.
          </Text>
        </Box>
        <Box sx={styles.right}>
          <Button
            as={Link}
            to='https://agileambition.substack.com?utm_source=agileambition&utm_medium=image&utm_campaign=middle&utm_content=newsletter-ad'
            variant='primary'
            aria-label='Join Now'
          >
            Join Now
          </Button>
        </Box>
      </Flex>

      <br />
    </Fragment>
  )
}

const bannerHorizontalQuery4 = graphql`
  query BannerHorizontalQuery4 {
    file(absolutePath: { regex: "/background.(jpeg|jpg|gif|png)/" }) {
      banner: childImageSharp {
        regular: resize(height: 300, cropFocus: CENTER) {
          src
        }
      }
    }
  }
`

export default NewsletterAd
