import React from 'react'
import { Fragment } from 'react'
//import AdSense from 'react-adsense';
// import { Divider } from 'theme-ui'




  const InArticalAd = props => (
      <Fragment>
        {/* <br/>
        <Divider />
        <AdSense.Google
            client='ca-pub-5773888715765748'
            slot='2560392537'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
          />
          <Divider />
          <br/> */}
      </Fragment>

      )
  
  export default InArticalAd

